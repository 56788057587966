import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
/**
 * @author Òscar Casajuana a.k.a. elboletaire <elboletaire at underave dot net>
 * @link https://github.com/elboletaire/password-strength-meter
 * @license GPL-3.0
 */
!function (h) {
  'use strict';

  function e(i, l) {
    function c(s, e) {
      for (var t = '', a = !1, n = 0; n < e.length; n++) {
        a = !0;
        for (var r = 0; r < s && r + n + s < e.length; r++) a = a && e.charAt(r + n) === e.charAt(r + n + s);
        r < s && (a = !1), a ? (n += s - 1, a = !1) : t += e.charAt(n);
      }
      return t;
    }
    return l = h.extend({}, {
      shortPass: 'The password is too short',
      badPass: 'Weak; try combining letters & numbers',
      goodPass: 'Medium; try using special characters',
      strongPass: 'Strong password',
      containsField: 'The password contains your username',
      enterPass: 'Type your password',
      showPercent: !1,
      showText: !0,
      animate: !0,
      animateSpeed: 'fast',
      field: !1,
      fieldPartialMatch: !0,
      minimumLength: 4,
      closestSelector: 'div'
    }, l), function () {
      var s = !0,
        n = l.showText,
        r = l.showPercent,
        e = h('<div>').addClass('pass-graybar'),
        o = h('<div>').addClass('pass-colorbar'),
        t = h('<div>').addClass('pass-wrapper').append(e.append(o));
      return i.closest(l.closestSelector).addClass('pass-strength-visible'), l.animate && (t.css('display', 'none'), s = !1, i.closest(l.closestSelector).removeClass('pass-strength-visible')), l.showPercent && (r = h('<span>').addClass('pass-percent').text('0%'), t.append(r)), l.showText && (n = h('<span>').addClass('pass-text').html(l.enterPass), t.append(n)), i.closest(l.closestSelector).append(t), i.keyup(function () {
        var s = l.field || '';
        s && (s = h(s).val());
        var e = function (s, e) {
          var t = 0;
          if (s.length < l.minimumLength) return -1;
          if (l.field) {
            if (s.toLowerCase() === e.toLowerCase()) return -2;
            if (l.fieldPartialMatch && e.length) {
              var a = new RegExp(e.toLowerCase());
              if (s.toLowerCase().match(a)) return -2;
            }
          }
          ;
          t += 4 * s.length, t += c(1, s).length - s.length, t += c(2, s).length - s.length, t += c(3, s).length - s.length, t += c(4, s).length - s.length, s.match(/(.*[0-9].*[0-9].*[0-9])/) && (t += 5);
          var n = '.*[!,@,#,$,%,^,&,*,?,_,~]';
          return n = new RegExp('(' + n + n + ')'), s.match(n) && (t += 5), s.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/) && (t += 10), s.match(/([a-zA-Z])/) && s.match(/([0-9])/) && (t += 15), s.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && s.match(/([0-9])/) && (t += 15), s.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && s.match(/([a-zA-Z])/) && (t += 15), (s.match(/^\w+$/) || s.match(/^\d+$/)) && (t -= 10), 100 < t && (t = 100), t < 0 && (t = 0), t;
        }(i.val(), s);
        i.trigger('password.score', [e]);
        var t = e < 0 ? 0 : e;
        if (o.css({
          backgroundPosition: '0px -' + t + 'px',
          width: t + '%'
        }), l.showPercent && r.html(t + '%'), l.showText) {
          var a = function (s) {
            return -1 === s ? l.shortPass : -2 === s ? l.containsField : (s = s < 0 ? 0 : s) < 34 ? l.badPass : s < 68 ? l.goodPass : l.strongPass;
          }(e);
          !i.val().length && e <= 0 && (a = l.enterPass), n.html() !== h('<div>').html(a).html() && (n.html(a), i.trigger('password.text', [a, e]));
        }
      }), l.animate && (i.focus(function () {
        s || t.slideDown(l.animateSpeed, function () {
          ;
          s = !0, i.closest(l.closestSelector).addClass('pass-strength-visible');
        });
      }), i.blur(function () {
        !i.val().length && s && t.slideUp(l.animateSpeed, function () {
          ;
          s = !1, i.closest(l.closestSelector).removeClass('pass-strength-visible');
        });
      })), this;
    }.call(this);
  }
  h.fn.password = function (s) {
    return this.each(function () {
      new e(h(this), s);
    });
  };
}(jQuery);